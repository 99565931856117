import React from 'react'
import { FormControl } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    padding: '5px 9px 9px 9px',
  },
})
export default function StyledCheckboxField({ form, field, labelstyle = {}, label, ...props }: any) {
  const { name, value } = field
  const helperText = form.errors[name]
  const error = !!helperText
  const classes = useStyles()

  return (
    <FormControl error={error} className="checkbox-container">
      <label style={labelstyle}>
        <Checkbox
          classes={classes}
          color="primary"
          {...props}
          checked={value}
          onChange={() => form.setFieldValue(name, !value)}
        />
        {label}
      </label>
    </FormControl>
  )
}
