const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN'

const initialState = {
  currentUser: null,
  openLogin: false,
}

export function setCurrentUser(user: any) {
  return { type: SET_CURRENT_USER, user }
}

export function setOpenLogin(isOpenLogin: boolean) {
  return { type: SET_OPEN_LOGIN, isOpenLogin }
}

export default function volunteerEventDetailReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
      }
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: action.isOpenLogin,
      }

    default:
      return state
  }
}
