import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import VolunteerEventRegister from './reducers/VolunteerEventRegister'
import VolunteerMyEvents from './reducers/MyEvents'
import VolunteerEventDetail from './reducers/EventDetail'
import PageHeader from './reducers/PageHeader'
import thunk from 'redux-thunk'
import BaseService from '../services/base.service'

const service = new BaseService()

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['Matches', 'Match', 'VolunteerEventRegister']
}

type InitialState = any

const reducers: any = combineReducers({
  VolunteerEventRegister,
  VolunteerEventDetail,
  VolunteerMyEvents,
  PageHeader,
})

const composeEnhancers = service.getEnvVar('ENV') === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

const enhancers = composeEnhancers(
  applyMiddleware(thunk)
)

const persistedReducer = persistReducer(persistConfig, reducers)
export const store = createStore(
  persistedReducer,
  {},
  enhancers
)
export const persistor = persistStore(store)
