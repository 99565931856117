import * as _ from 'lodash'
import { AnswerPayload, Question, QuestionSetResponse, QuestionType } from '../types'

export const questionTypeCodeToDefaultAnswer = {
  [QuestionType.SHORT_TEXT]: '',
  [QuestionType.LONG_TEXT]: '',
  [QuestionType.SINGLE_CHOICE]: '',
  [QuestionType.DROPDOWN]: '',
  [QuestionType.MULTIPLE_CHOICE]: [],
  [QuestionType.TRUE_OR_FALSE]: false,
  [QuestionType.NUMBER]: 0,
  [QuestionType.PHONE_NUMBER]: '',
  [QuestionType.EMAIL]: '',
  [QuestionType.DOCUMENT]: '',
  [QuestionType.HOME_ADDRESS]: {},
  [QuestionType.EMERGENCY_CONTACT]: {},
}

export interface ResponseByQuestionId {
  [key: string]: any
}

const getResponse = (question: Question) =>
  _.isEmpty(question.response) ? questionTypeCodeToDefaultAnswer[question.questionType] : question.response

export const initQuestionAnswers = (questionSetResponse: QuestionSetResponse): Question[] => {
  if (!questionSetResponse) {
    return []
  }
  const { questions } = questionSetResponse

  const responseByQuestionId: ResponseByQuestionId = questions.reduce(
    (acc: ResponseByQuestionId, question: Question) => {
      acc[question.questionId] = getResponse(question)
      return acc
    },
    {},
  )
  return questions.map(question => {
    const { conditionedOnQuestionId, conditionedOnAnswer, otherAnswer } = question
    return {
      ...question,
      response: getResponse(question),
      otherAnswer: _.isEmpty(otherAnswer) ? '' : otherAnswer,
      disabled: conditionedOnQuestionId ? responseByQuestionId[conditionedOnQuestionId] !== conditionedOnAnswer : false,
    }
  })
}

export const mapQuestionToAnswerPayload = (questionWithAnswer: Question): AnswerPayload => ({
  answerSetId: _.get(questionWithAnswer, 'answerSet.id', undefined),
  answerId: _.get(questionWithAnswer, 'answerId', undefined),
  questionId: questionWithAnswer.questionId,
  response: questionWithAnswer.disabled
    ? questionTypeCodeToDefaultAnswer[questionWithAnswer.questionType]
    : questionWithAnswer.response,
  otherAnswer: questionWithAnswer.disabled ? '' : questionWithAnswer.otherAnswer,
  completed: questionWithAnswer.disabled ? false : !_.isEmpty(questionWithAnswer.response),
})

export const OTHER_OPTION = 'Other'
