const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN'

const initialState = {
  openLogin: false
}

export function setOpenLogin(isOpenLogin: boolean) {
  return { type: SET_OPEN_LOGIN, isOpenLogin }
}

export default function volunteerEventDetailReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_OPEN_LOGIN:
      return {
        ...state,
        openLogin: action.isOpenLogin
      }

    default:
      return state
  }
}
